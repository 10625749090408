.footer-box {
  background-color: #001529;

  .footer-box-row {
    @media (max-width: 767px) {
      display: none;
    }
    .left-info{
      padding-left: 10vw;
      text-align: left;
      color: #999;
      line-height: 30px;
    }
    h2 {
      color: #fff;
    }

    a {
      display: block;
      //font-size: 3vw;
      margin-bottom: 12px;
    }
    .slogan{
      color: #999;
    }
    .title.code{
      padding-top: 20px;
    }
  }

  .footer-box-info {
    color: #999;
    text-align: left;
    font-size: 12px;
  }

}

@media (max-width: 768px){
  .ant-layout-footer{
    padding: 24px 10px;
  }
}

.record-info {
  border-top: 1px solid rgba(233, 233, 233, .1);
  color: #999;
  padding-top: 20px;
  //height:10vh;
  a {
    //div{
    //  display: inline-block;
    //  width: 1.2vw;
    //  height: 1.2vw;
    //  margin-right: 3px;
    //  background-image: url('images/gongan.png');
    //}
  }

  span {
    margin-left: 10px;
  }
}

@primary-color: #00a671;@link-color: #999;