.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
#logo-img{
  top: -40px;
}
#alt-logo{
  @media(max-width: 768px){
    display: none;
  }
  @media(min-width: 768px){
    display: none;
  }
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.ant-menu-vertical {
  min-width: 50px !important;
}

.site-layout-content{
  min-height: 1000px;
  @media(max-width: 768px){
    min-height: 300px;
  }
  padding: 0;
  background: #f0f2f5;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after{
  border-bottom: 4px solid @primary-color;
}
@primary-color: #00a671;@link-color: #999;